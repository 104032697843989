@import "../../styles/globals.scss";

.about {
  padding: to-rem(60px) 0 to-rem(80px) 0;

  @include breakpoint(tabletS) {
    padding: to-rem(200px) 0 to-rem(80px) 0;
  }

  @include breakpoint(laptop) {
    padding: to-rem(200px) 0 to-rem(80px) 0;
  }

  &__heading {
    position: relative;
    @include heading-3-mobile;
    opacity: 0;
    color: white;
    transition: 1s ease;

    @include breakpoint(tabletS) {
      @include heading-4;
    }

    @include breakpoint(laptop) {
      @include heading-5-6;
    }

    @include breakpoint(desktopL) {
      @include heading-6;
    }
  }

  &__heading-underline {
    display: none;

    @include breakpoint(tabletS) {
      display: block;
      width: 0%;
      height: to-rem(1px);
      background-color: #8d868d;
      margin-bottom: to-rem(60px);
      margin-top: to-rem(10px);
      border-radius: to-rem(10px);
    }

    &--animate {
      animation: 1.5s ease-in-out 1.5s underlineAnimate forwards;
    }
  }

  &__container {
    width: 100%;
    max-width: to-rem(1300px);
    margin: 0 auto;

    @include breakpoint(tabletS) {
      display: flex;
    }
  }

  &__profile-image-animation-container {
    overflow: hidden;
    position: relative;
    width: to-rem(311px);
    height: to-rem(466.5px);
    margin-left: to-rem(-32px);

    @include breakpoint(tabletS) {
      margin-left: 0px;
      height: unset;
      width: 45%;
    }

    @include breakpoint(laptop) {
      width: 40%;
    }
  }

  &__profile-image-container {
    overflow: hidden;
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    right: 100%;
    bottom: 100%;

    &--animate {
      animation: 1.5s ease-in-out aboutImageIn forwards;
    }
  }
  &__profile-image {
    width: to-rem(311px);
    height: to-rem(466.5px);
    transition: 0.5s ease-in;

    @include breakpoint(tabletS) {
      height: unset;
      transform: translateX(-130px);
    }

    @include breakpoint(tabletL) {
      transform: translate(-130px, -40px);
    }

    @include breakpoint(laptop) {
      transform: translate(-15%, -4%);
    }

    @include breakpoint(desktopS) {
      transform: translate(-10%, -5%);
    }

    &:hover {
      transform: scale(1.2) rotate(3deg);

      @include breakpoint(laptop) {
        transform: translateY(-5%) scale(1.2) rotate(3deg);
      }

      @include breakpoint(desktopS) {
        transform: translateY(-10%) scale(1.2) rotate(3deg);
      }

      @include breakpoint(desktopL) {
        transform: translateY(-15%) scale(1.2) rotate(3deg);
      }
    }
  }

  &__bio-container {
    padding-right: to-rem(45px);
    margin-top: to-rem(30px);
    color: white;
    transition: 1s ease;

    @include breakpoint(tabletS) {
      width: 55%;
      padding-right: 0;
      margin-top: 0;
      // flex-grow: 1;
      margin-left: to-rem(30px);
    }

    @include breakpoint(laptop) {
      width: 60%;
      min-height: to-rem(455px);
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    @include breakpoint(desktopS) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: to-rem(60px);
      min-height: to-rem(500px);
    }

    &--black {
      color: black;
    }
  }

  &__greeting {
    @include heading-5-mobile;
    opacity: 0;
    position: relative;

    @include breakpoint(tabletS) {
      @include heading-6;
    }

    @include breakpoint(laptop) {
      @include heading-8;
    }

    &--animate {
      animation: 1.5s ease-in-out aboutSlideInLeft forwards;
    }

    &--animate-two {
      animation: 1.5s ease-in-out 0.5s aboutSlideInLeft forwards;
    }
  }

  &__bio {
    margin-top: to-rem(20px);
    opacity: 0;
    position: relative;
    @include body-small-mobile;

    @include breakpoint(tabletS) {
      @include body-large;
    }

    @include breakpoint(laptop) {
      @include body-regular;
    }

    @include breakpoint(desktopS) {
      @include body-regular;
    }
  }

  &__email {
    font-family: "Serif Black";
  }

  &__social-container {
    width: 100%;
    display: flex;
    margin-top: to-rem(30px);
  }

  &__anchor {
    display: inline-block;
  }

  &__icons {
    height: to-rem(28px);
    width: to-rem(28px);
    margin-right: to-rem(15px);
    position: relative;
    opacity: 0;
    transition: 0.5s ease-in-out;

    &--one {
      animation: 1s ease-in-out aboutSlideInLeft forwards;
    }

    &--two {
      animation: 1s ease-in-out 0.5s aboutSlideInLeft forwards;
    }

    &--three {
      animation: 1s ease-in-out 1s aboutSlideInLeft forwards;
    }

    &:hover {
      transform: translateY(-5px);
    }
  }
}

@keyframes underlineAnimate {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@keyframes aboutSlideInLeft {
  0% {
    opacity: 0;
    left: -40px;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

@keyframes aboutImageIn {
  0% {
    top: 0;
    left: 0;
    right: 100%;
    bottom: 99%;
  }
  50% {
    top: 0;
    left: 0;
    right: 0;
    bottom: 99%;
  }
  100% {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
